import {observer} from "mobx-react";
import React, {
    Dispatch,
    forwardRef, ReactElement,
    SetStateAction, useContext,
    useEffect, useImperativeHandle,
    useRef,
    useState,
} from "react";
import styles from "./styles/NewsSelector.module.scss";
import {
    IReactiveCourseWorkContentItem, IReactiveEventContentItem,
    IReactiveNewsContentItem,
    ReactiveContentItem,
    SchoolFeedContentItem
} from "../../../../stores/SchoolFeedStore";
import moment from "moment";
import classNames from "classnames";
import {SocialItemModalNavigationProps} from "../../../modals/SocialItemModal";
import SchoolFeedList from "../../../schoolfeed/SchoolFeedList";
import Scrollbar from "react-scrollbars-custom";
import {CSSTransition} from "react-transition-group";
import {StoreContext} from "../../../../stores/StoreLoader";
import {reactScrollBarsCustomWidth} from "../../../../utils/SchoolBlocksUtilities";
import {getListItemIndex, scrollItemId} from "../../../schoolfeed/ListItemGroup";

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

const NewsItem = observer((props: {
    item: SchoolFeedContentItem | NewsContentItem | ReactiveContentItem,
    selectedItem?: SchoolFeedContentItem | NewsContentItem | ReactiveContentItem,
    setSelectedItem: Dispatch<SetStateAction<SchoolFeedContentItem | NewsContentItem | ReactiveContentItem>>,
}) => {
    const {interfaceStore} = useContext(StoreContext);

    const newsItemClassName = classNames({
        [styles.newsItem]: true,
        [styles.newsItemSelected]: props.item.id === props.selectedItem?.id,
    })

    return <div className={newsItemClassName}>
        <div className={styles.newsItemInfo}>
            <h3>{props.item.title}</h3>
            <small>{interfaceStore.view === 'schoolfeed' && (props.item.organization.title + ' - ')} {moment(props.item.created_at).fromNow()}</small>
        </div>
    </div>
})

type NewsItemExpandedViewProps = {
    selectedItem: SchoolFeedContentItem | NewsContentItem | ReactiveContentItem,
    viewMoreButton?: ReactElement,
    firstVisibleIndex: number,
    setFirstVisibleIndex: Dispatch<SetStateAction<number>>,
    setSelectedItem: Dispatch<SetStateAction<SchoolFeedContentItem | NewsContentItem | ReactiveContentItem>>,
    handleDelete?: (c: IReactiveNewsContentItem) => void,
    contentItems: Array<IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem>,
} & Omit<SocialItemModalNavigationProps, "initialItemIndex">

interface NewsItemExpandedViewHandle {
    scrollbarRef: HTMLDivElement | null;
    newsItemExpandedScrollContainerRef: HTMLDivElement | null;
}

const NewsItemExpandedView = forwardRef<NewsItemExpandedViewHandle, NewsItemExpandedViewProps>((props, ref) => {
    NewsItemExpandedView.displayName = 'NewsItemExpandedView';

    const visibleIndices = useRef(new Set([0])); // Using useRef to track visible indices without causing re-renders

    const newsItemExpandedScrollContainerRef = useRef<HTMLDivElement | null>(null);
    const scrollbarRef = useRef(null);

    // Exposing the refs to the parent component
    useImperativeHandle(ref, () => ({
        scrollbarRef: scrollbarRef.current,
        newsItemExpandedScrollContainerRef: newsItemExpandedScrollContainerRef.current,
    }), []);

    useEffect(() => {
        const updateVisibility = (entries) => {
            const current = newsItemExpandedScrollContainerRef.current
            if (current) {
                const nodesArray = Array.from(current.querySelectorAll(`.${scrollItemId}`));
                entries.forEach(entry => {
                    const index = nodesArray.indexOf(entry.target);
                    if (entry.isIntersecting && entry.intersectionRatio >= 0.95) {
                        visibleIndices.current.add(index);
                    } else {
                        visibleIndices.current.delete(index);
                    }
                });
            }

            if (visibleIndices.current.size > 0) {
                props.setFirstVisibleIndex(Math.min(...visibleIndices.current));
            }
        };

        const current = newsItemExpandedScrollContainerRef.current
        if (current) {
            const observer = new IntersectionObserver(updateVisibility, {
                root: current,
                threshold: 0.95,
            });

            const items = current.querySelectorAll(`.${scrollItemId}`);
            items?.forEach(item => observer.observe(item));

            return () => {
                items?.forEach(item => observer.unobserve(item));
                observer.disconnect();
            };
        }

    }, [props.contentItems]);

    useEffect(() => {
        props.setSelectedItem(props.contentItems[props.firstVisibleIndex])
    },[props.firstVisibleIndex])

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "ArrowLeft") {
            e.preventDefault();
            const matchedIndex = getListItemIndex()
            const leftItem = document.getElementById(`${newsTerraceItem}${matchedIndex}`);
            if (leftItem) {
                leftItem.focus();
            }
        }
    }

    return <div ref={newsItemExpandedScrollContainerRef} className={styles.expandedView}>
        <Scrollbar
            disableTrackYWidthCompensation={true}
            scrollbarWidth={reactScrollBarsCustomWidth}
            ref={scrollbarRef}
            trackYProps={{
                style: {right: -15},
                className: styles.scrollBarStyle,
            }}
            scrollerProps={{className: styles.scrollBarStyle, tabIndex: 0}}
            wrapperProps={{className: styles.scrollBarStyle}}
        >
            <SchoolFeedList
                showMedia={true}
                allowCuration={true}
                content={props.contentItems}
                totalItemCount={props.totalItemCount}
                handleDelete={props.handleDelete}
                className={styles.schoolFeedList}
                getItemAtIndex={props.getItemAtIndex}
                handleKeyDown={handleKeyDown}
            />
            <div className={styles.viewMoreButton}>
                {props.viewMoreButton}
            </div>
        </Scrollbar>
    </div>
})

const newsTerraceItem = "newsTerraceItem-";

const NewsSelector = observer((props: {
    contentItems: Array<IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem>,
    viewMoreButton?: ReactElement,
    aboveTheFold: boolean,
    handleDelete?: (c: ContentItem) => void,
    className?: string,
    hideLeftColumn?: boolean,
} & Omit<SocialItemModalNavigationProps, "initialItemIndex">) => {
    const {interfaceStore} = useContext(StoreContext);
    const [selectedItem, setSelectedItem] = useState(props.contentItems[0])
    const newsItemScrollContainerRef = useRef<HTMLDivElement | null>(null);
    const leftPaneTransitionRef = useRef(null);
    const expandedViewTransitionRef = useRef(null);

    const [firstVisibleIndex, setFirstVisibleIndex] = useState<number>(0);
    const [disableScroll, setDisableScroll] = useState(false);

    const scrollbarRef = useRef(null);

    const timeoutRef = useRef<number | null>(null);

    useEffect(() => {
        const scrollContainer = newsItemScrollContainerRef.current;

        if (scrollContainer && !disableScroll) {
            // Clear any previously set timeout
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            // Set a new timeout
            timeoutRef.current = window.setTimeout(() => {
                const targetItem = scrollContainer.querySelector<HTMLElement>(`#${newsTerraceItem}${firstVisibleIndex}`);
                if (targetItem) {
                    const scrollbarRefCurrent = scrollbarRef.current as unknown as HTMLDivElement;
                    if (scrollbarRefCurrent) {
                        scrollbarRefCurrent.scrollTo(0, targetItem.offsetTop);
                    }
                }
            }, 1500);
        }

        // Clean up timeout when component unmounts or dependencies change
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [firstVisibleIndex]);

    useEffect(() => {
        // Sets disableScroll back to default value after 2s, which should be enough time for
        // the expanded view to scroll to the selected news item
        if (disableScroll) {
            setTimeout(() => {
                setDisableScroll(false)
            },2000)
        }
    },[disableScroll])

    const newsSelectorRef = useRef<NewsItemExpandedViewHandle>(null);

    const newsSelectorContainerClassName = classNames({
        [styles.newsSelectorContainer]: true,
        [props.className || '']: props.className,
    })

    const rightPaneTransitionContainerClassname = classNames({
        [styles.rightPaneTransitionContainer]: true,
        [styles.rightPaneTransitionContainerHideLeftColumn]: props.hideLeftColumn,
    })

    return (
        <div className={newsSelectorContainerClassName}>
            {!props.hideLeftColumn && <div className={styles.leftPaneTransitionContainer}>
                <CSSTransition appear in={props.aboveTheFold} timeout={500} classNames={transitionClassNames} nodeRef={leftPaneTransitionRef}>
                    <div className={styles.leftPaneContainer} ref={leftPaneTransitionRef}>
                        <div ref={newsItemScrollContainerRef}>
                            <Scrollbar
                                scrollbarWidth={reactScrollBarsCustomWidth}
                                disableTrackYWidthCompensation={true}
                                className={styles.newsItemScrollbar} ref={scrollbarRef}
                                scrollerProps={{tabIndex: 0}}
                                trackYProps={ {style: {right: 5}} }>
                                {
                                    props.contentItems.map((item, idx) => {
                                        const onClick = (e) => {
                                            setDisableScroll(true);
                                            if (newsSelectorRef.current) {
                                                const scrollContainer = newsSelectorRef.current.newsItemExpandedScrollContainerRef;
                                                if (scrollContainer) {
                                                    const targetItem: HTMLDivElement | null = scrollContainer.getElementsByClassName(scrollItemId)[idx] as HTMLDivElement
                                                    if (targetItem) {
                                                        const scrollbarRef = newsSelectorRef.current.scrollbarRef;
                                                        if (scrollbarRef) {
                                                            scrollbarRef.scrollTo(0, targetItem.offsetTop - 20);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        return <button id={`${newsTerraceItem}${idx}`}
                                                       onKeyDown={e => {
                                                           if (e.key === "ArrowRight") {
                                                               e.preventDefault();
                                                               const rightItem = document.getElementsByClassName(scrollItemId)[idx] as HTMLDivElement
                                                               if (rightItem) {
                                                                   rightItem.focus();
                                                               }
                                                           }
                                                       }}
                                                       onClick={onClick}>
                                            <NewsItem key={item.id} item={item} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>
                                        </button>
                                    })
                                }
                                {interfaceStore.view === 'schoolfeed' && props.viewMoreButton && <div className={styles.viewMoreButton}>
                                    {props.viewMoreButton}
                                </div>}
                            </Scrollbar>
                        </div>
                    </div>
                </CSSTransition>
            </div>}
            <div className={rightPaneTransitionContainerClassname}>
                <CSSTransition appear in={props.aboveTheFold} timeout={500} classNames={transitionClassNames} nodeRef={expandedViewTransitionRef}>
                    <div className={styles.rightPaneContainer} ref={expandedViewTransitionRef}>
                        <NewsItemExpandedView
                            ref={newsSelectorRef}
                            selectedItem={selectedItem}
                            firstVisibleIndex={firstVisibleIndex}
                            setFirstVisibleIndex={setFirstVisibleIndex}
                            contentItems={props.contentItems}
                            handleDelete={props.handleDelete}
                            setSelectedItem={setSelectedItem}
                            getItemAtIndex={props.getItemAtIndex}
                            totalItemCount={props.totalItemCount}
                            viewMoreButton={props.viewMoreButton}/>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
})

export default NewsSelector;
